<!-- 订单中心 -->
<template>
  <div id="productrule">
    <van-nav-bar :title="$t('m_productList.gz')" left-arrow @click-left="onClickLeft">
    </van-nav-bar>
    <div class="footer">
      <button class="wei-btn">{{$t('m_other.qwk')}}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/mobile.less";
.van-nav-bar {
  background-image: linear-gradient(0deg, #ceab77 10%, #eddbc1 100%) !important;
}
/deep/.van-nav-bar__title {
  color: #38373b !important;
}
/deep/.van-nav-bar .van-icon {
  color: #38373b !important;
}
#productrule{
  width: 100%;
  height: 100%;
  max-width: 750px;
  margin: 0 auto;
  background: #222;
  .footer{
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    height: .9rem;
    max-width: 750px;
    .wei-btn{
      margin-top: 0;
      -webkit-box-flex: 1;
      flex: 1;
      border-radius: 0;
      font-size: .32rem;
      background-image: linear-gradient(0deg, #ceab77 20%, #eddbc1 100%);
      color: #000;
    }
    .wei-btn:after{
      content: " ";
      width: 200%;
      height: 200%;
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid rgba(0,0,0,.2);
      transform: scale(.5);
      transform-origin: 0 0;
      box-sizing: border-box;
      border-radius: 10px;
    }
  }
}
</style>